import React from "react";

import { useTranslation } from "react-i18next";
import eng from "../assets/img/eng.svg";
import ger from "../assets/img/ger.svg";

import { availableLanguages } from "../i18next";

const TranslateComponent: React.FC = () => {
  const { t, i18n } = useTranslation();

  // init availableLanguages
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => {
      console.log("Language changed to: ", lng);
    });
  };

  availableLanguages.map((language) => () => {});

  return (
    <>
      <div className={"flex flex-row justify-center mt-10 bottom-10"}>
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={() => changeLanguage("en")}
            className="py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white w-12 h-12"
          >
            <img src={eng} alt={t("en")} />
          </button>

          <button
            type="button"
            onClick={() => changeLanguage("de")}
            className="py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white w-12 h-12"
          >
            <img src={ger} alt={t("de")} />
          </button>
        </div>
      </div>
      <div className="text-center pt-3 hidden">
        <span className="text-gray-800 dark:text-white font-bold">TC-AdHoc -VERSION_NUMBER-</span>
      </div>
    </>
  );
};

export default TranslateComponent;
