import React, { useContext, useEffect, useState } from "react";
import { paramProps } from "../commom/types";
import { AppCtx } from "../commom/app.context";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

const PriceComponent: React.FC<paramProps> = ({ evseID, children }) => {
  const { t, i18n } = useTranslation(["common", "price"]);

  const [perTransactionPrice, setPerTransactionPrice] = useState("0");
  const [perMinutePrice, setPerMinutePrice] = useState("0");
  const [perKWHPrice, setPerKWHPrice] = useState("0");
  const [blockingFee, setBlockingFee] = useState("0");

  const [timer, setTimer] = useState("");

  const appCtx = useContext(AppCtx);

  useEffect(() => {
    // setTimer
    const interval = setInterval(() => {
      const now = new Date();
      if (appCtx?.price?.validUntil === undefined) return;

      const validUntil = new Date(appCtx?.price?.validUntil);
      // show timer in minute:seconds format
      // calculate the difference between now and validUntil
      // and show a countdown in minute and seconds separated by a colon
      // for example 18:59
      const diff = validUntil.getTime() - now.getTime();

      if (diff <= 0) {
        // set the diff to 0
        // reload page
        // wait for 1 second before reload
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setTimer("00:00");
        return;
      }
      const minutes = Math.floor(diff / 60000);
      const seconds = Math.floor((diff % 60000) / 1000);
      // append 0 if seconds is less than 10
      let secondString = seconds.toString();
      let minuteString = minutes.toString();
      if (seconds < 10) {
        secondString = "0" + seconds;
      }
      if (minutes < 10) {
        minuteString = "0" + minutes;
      }

      setTimer(minuteString + ":" + secondString);
    }, 1000);

    return () => clearInterval(interval);
  }, [appCtx?.price?.validUntil]);

  useEffect(() => {
    const price = appCtx?.price?.perTransaction || 0;

    if (i18n.language === "de") {
      setPerTransactionPrice(price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }));
    } else {
      setPerTransactionPrice(price.toLocaleString("en-US", { style: "currency", currency: "EUR" }));
    }
  }, [i18n.language, appCtx?.price?.perTransaction]);

  useEffect(() => {
    const price = appCtx?.price?.perMinute || 0;

    if (i18n.language === "de") {
      setPerMinutePrice(price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }));
    } else {
      setPerMinutePrice(price.toLocaleString("en-US", { style: "currency", currency: "EUR" }));
    }
  }, [i18n.language, appCtx?.price?.perMinute]);

  useEffect(() => {
    const price = appCtx?.price?.perKWH || 0;

    if (i18n.language === "de") {
      setPerKWHPrice(price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }));
    } else {
      setPerKWHPrice(price.toLocaleString("en-US", { style: "currency", currency: "EUR" }));
    }
  }, [i18n.language, appCtx?.price?.perKWH]);

  useEffect(() => {
    const price = appCtx?.price?.blockingFee || 0;

    if (i18n.language === "de") {
      setBlockingFee(price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }));
    } else {
      setBlockingFee(price.toLocaleString("en-US", { style: "currency", currency: "EUR" }));
    }
  }, [i18n.language, appCtx?.price?.blockingFee]);

  return (
    <>
      <div className="flex flex-col">
        <div>
          {appCtx?.price?.perTransaction !== undefined || <Skeleton count={1} className="animate-pulse" />}

          <span className={appCtx?.price?.perTransaction !== undefined && appCtx?.price?.perTransaction !== 0 ? "" : "hidden"}>
            {" "}
            {appCtx?.price?.perTransaction !== 0 ? perTransactionPrice + " " + t("price:fee") : ""}
          </span>
        </div>
        <div>
          {appCtx?.price?.perKWH !== undefined || <Skeleton count={1} className="animate-pulse" />}

          <span className={appCtx?.price?.perKWH !== undefined && appCtx?.price?.perKWH !== 0 ? "" : "hidden"}>
            {" "}
            {appCtx?.price?.perKWH !== 0 ? perKWHPrice + " " + t("price:energy") : ""}
          </span>
        </div>
        <div>
          {appCtx?.price?.perMinute !== undefined || <Skeleton count={1} className="animate-pulse" />}

          <span className={appCtx?.price?.perMinute !== undefined && appCtx?.price?.perMinute !== 0 ? "" : "hidden"}>
            {" "}
            {appCtx?.price?.perMinute !== 0 ? perMinutePrice + " " + t("price:minute") : ""}
          </span>
        </div>
        <div>
          {appCtx?.price?.blockingFee !== undefined || <Skeleton count={1} className="animate-pulse" />}

          <span className={appCtx?.price?.blockingFee !== undefined && appCtx?.price?.blockingFee !== 0 ? "" : "hidden"}>
            {" "}
            {appCtx?.price?.blockingFee !== 0 ? blockingFee + t("price:blocking") : ""}
          </span>
        </div>

        {appCtx?.price?.perTransaction !== 0 ||
        appCtx?.price?.perKWH !== 0 ||
        appCtx?.price?.perMinute !== 0 ||
        appCtx?.price?.blockingFee !== 0 ? (
          children
        ) : (
          <div className="mt-5">{t("price:free")}</div>
        )}

        {appCtx?.price?.scheduled ? (
          <div className="text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <div>
              <span className="font-medium"> {t("price:alert")} </span>
              {t("price:change_due")}
            </div>

            <div>
              {t("price:valid_until", {
                time: getHour(appCtx?.price?.validUntil)
              })}
              &nbsp;
              {timer
                ? t("price:countdown", {
                    timer: timer
                  })
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PriceComponent;

function getHour(time: string | undefined) {
  // example return 18:59  from 2024-04-29 18:59:59
  if (time === undefined) return "";
  return time.split(" ")[1].split(":")[0] + ":" + time.split(" ")[1].split(":")[1];
}
